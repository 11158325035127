<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-estado">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Actualizar Estado</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="estado">Estado</label>
              <select
                id="estado"
                class="form-control form-control-sm"
                v-model="form_estado.estado"
                :class="
                  $v.form_estado.estado.$invalid ? 'is-invalid' : 'is-valid'
                "
                @input="limpiarFechas()"
              >
                <option value="">Seleccione...</option>
                <template v-for="estado in $parent.listasForms.estados">
                  <option
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.guias.estado' + estado.descripcion
                      )
                    "
                    :key="estado.numeracion"
                    :value="estado.numeracion"
                  >
                    {{ estado.descripcion }}
                  </option>
                </template>
              </select>
              <div class="error" v-if="!$v.form_estado.estado.required">
                Seleccione un estado
              </div>
            </div>
            <div class="form-group">
              <label for="justificacion">Justificacion</label>
              <textarea
                v-model="form_estado.justificacion"
                cols="30"
                rows="5"
                class="form-control form-control-sm"
                :class="
                  $v.form_estado.justificacion.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              ></textarea>
              <div class="error" v-if="!$v.form_estado.justificacion.required">
                Ingrese una Justificacion
              </div>
            </div>
            <div class="form-group" v-show="form_estado.estado == 3">
              <label for="fecha_anulacion">Fecha Anulación</label>
              <div class="row">
                <div class="col-md-7">
                  <input
                    type="date"
                    v-model="form_estado.fecha_anulacion"
                    style="font-size: 13px"
                    class="form-control form-control-sm"
                    :class="
                      $v.form_estado.fecha_anulacion.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <div class="col-md-5">
                  <input
                    v-model="form_estado.hora_anulacion"
                    type="text"
                    class="form-control form-control-sm"
                    style="font-size: 13px"
                    v-mask="{
                      mask: '99:99:99',
                      hourFormat: '24',
                    }"
                    :class="
                      $v.form_estado.hora_anulacion.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form_estado.$invalid"
              @click="save()"
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "GuiaEstado",
  data() {
    return {
      form_estado: {
        estadoAnt: null,
        estado: null,
        justificacion: null,
        fecha_anulacion: null,
        hora_anulacion: null,
      },
    };
  },
  validations() {
    let fecha_anulacion = {};
    let hora_anulacion = {};

    if (this.form_estado.estado != 3) {
      fecha_anulacion = {
        required: false,
      };

      hora_anulacion = {
        required: false,
      };
    } else {
      fecha_anulacion = {
        required,
      };

      hora_anulacion = {
        required,
      };
    }

    return {
      form_estado: {
        estado: {
          required,
        },
        justificacion: {
          required,
          minLength: minLength(10),
        },
        fecha_anulacion: fecha_anulacion,
        hora_anulacion: hora_anulacion,
      },
    };
  },
  methods: {
    save() {
      if (!this.$v.form_estado.$invalid) {
        if (this.form_estado.estadoAnt == 2 && this.form_estado.estado == 8) {
          this.$swal({
            icon: "error",
            title:
              "Las guias con estado usado no pueden cambiarse a estado de cambio",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          axios({
            method: "PUT",
            url: "/api/hidrocarburos/guias/" + this.form_estado.id,
            data: this.form_estado,
          })
            .then((response) => {
              this.form_estado = {};
              this.$refs.closeModal.click();
              this.$parent.getIndex();
              this.$swal({
                icon: "success",
                title: "Se realizo el cambio de estado exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch((e) => {
              this.cargando = false;
              this.$swal({
                icon: "error",
                title: "Ocurrio un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      }
    },
    llenar_modal(id, val_ant) {
      this.form_estado.id = id;
      this.form_estado.estadoAnt = val_ant;
    },

    limpiarFechas() {
      this.form_estado.fecha_anulacion = null;
      this.form_estado.hora_anulacion = null;
    },
  },
};
</script>
